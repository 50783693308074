<template>
    <b-card>
        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Mostrar" append="registros">
                    <b-form-select v-model="serviceOrders.itemsPerPage" id="perPageSelect" size="sm" :options="serviceOrders.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>

<!--            <b-col sm="7" md="6" class="my-1">-->
<!--                <b-input-group prepend="Filtrar: ">-->
<!--                    <b-input v-model="serviceOrders.filter" debounce="300"></b-input>-->
<!--                </b-input-group>-->
<!--            </b-col>-->
        </b-row>
        <b-table :items="loadData" :fields="serviceOrders.fields" :filter="serviceOrders.filter" :current-page="serviceOrders.currentPage"
                 :per-page="serviceOrders.itemsPerPage" empty-text="Sin registros" empty-filtered-text="Sin coincidencias"
                 striped bordered hover small show-empty :responsive="true" :sort-by="serviceOrders.sortBy" :sort-desc="serviceOrders.sortDesc"
                 ref="serviceOrdersTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in serviceOrders.fields" :key="field.key"
                          :class="serviceOrders.filters[index] && serviceOrders.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="serviceOrders.filters[index]">
                            <b-col v-if="serviceOrders.filters[index].inputType === 'Select'">
                                <b-select v-model="serviceOrders.filters[index].value">
                                    <template v-if="'selectOptions' in serviceOrders.filters[index]">
                                        <b-select-option v-for="option in serviceOrders.filters[index].selectOptions" :value="option.value" :key="option.value">
                                            {{ option.text }}
                                        </b-select-option>
                                    </template>
                                    <template v-else>
                                        <b-select-option value="">Todos</b-select-option>
                                        <b-select-option value="'DRAFT'">Borrador</b-select-option>
                                        <b-select-option value="'OPEN'">Abierta</b-select-option>
                                        <b-select-option value="'FINISHED'">Terminada</b-select-option>
                                        <b-select-option value="'CANCELLED'">Cancelada</b-select-option>
                                    </template>
                                </b-select>
                            </b-col>
                            <b-col v-else-if="serviceOrders.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="serviceOrders.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(folio)="row">
                <p><router-link :to="{ name: 'ServiceOrderShow', params: { id: row.item.id }}" target="_blank">{{ row.item.folio }}</router-link></p>
            </template>

            <template #cell(enabled)="row">
                <b-badge variant="primary" v-if="row.item.enabled" class="p-1">Activo</b-badge>
                <b-badge variant="warning" v-else>Inactivo</b-badge>
            </template>

            <template #cell(startDate)="row">
<!--                <p>{{ row.item.startDate + ' - ' + row.item.endDate }}</p>-->
                <p v-if="row.item.startDate !== row.item.endDate">{{ row.item.startDate }} - {{ row.item.endDate }}</p>
                <p v-else>{{ row.item.startDate }}</p>
            </template>

            <template #cell(passengers)="row">
                <p>{{ row.item.adultsQty + row.item.childrenQty}}</p>
            </template>

            <template #cell(vehicle)="row">
                <p>{{ row.item.vehicleBrand }} {{row.item.vehicleModel }} [{{ row.item.vehicleLicensePlate }}]</p>
            </template>

            <template #cell(status)="row">
                <b-badge variant="warning" v-if="row.item.status === 'DRAFT'" class="p-1">Borrador</b-badge>
                <b-badge variant="info" v-if="row.item.status === 'OPEN'" class="p-1">Abierta</b-badge>
                <b-badge variant="primary" v-if="row.item.status === 'FINISHED'" class="p-1">Terminada</b-badge>
                <b-badge variant="danger" v-if="row.item.status === 'CANCELLED'" class="p-1">Cancelada</b-badge>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" :to="{ name: 'ServiceOrderShow', params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Detalle" variant="outline-primary">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Mostrando {{ start }} a {{ end }} de {{ serviceOrders.totalRows }} registro<template v-if="serviceOrders.totalRows !== 1">s</template>
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="serviceOrders.currentPage" :total-rows="serviceOrders.totalRows"
                                      :per-page="serviceOrders.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import { serviceOrderListUrl } from '@routes';
import * as constants from '@constants';
import { formatCurrency } from '../../js/utilities';
import tableStateMixin from '../../mixins/tableState';

export default {
    mixins: [tableStateMixin],
    data() {
        return {
            serviceOrders: {
                fields: [
                    { key: 'startDate', label: 'Fecha', sortable: true, thStyle: 'width: 10%' },
                    { key: 'folio', label: 'Folio', sortable: true, thStyle: 'width: 8%' },
                    { key: 'serviceName', label: 'Servicio', sortable: true },
                    { key: 'type', label: 'Tipo', sortable: true },
                    { key: 'passengers', label: 'Pasajeros', sortable: true, class: 'text-right' },
                    { key: 'operatorName', label: 'Operador', sortable: true },
                    { key: 'vehicle', label: 'Vehículo', sortable: true },
                    { key: 'status', label: 'Estatus', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Acciones', class: 'text-center' }
                ],
                filters: [
                    { column: `IF(so.start_date = so.end_date, DATE_FORMAT(so.start_date, '%d/%m/%Y'), CONCAT(DATE_FORMAT(so.start_date, '%d/%m/%Y'), ' - ', DATE_FORMAT(so.end_date, '%d/%m/%Y')))`, type: 'STRING', value: '', format: '' },
                    { column: 'so.folio', type: 'STRING', value: '', format: '' },
                    { column: 'so.service_name', type: 'STRING', value: '', format: '' },
                    { column: 'so.service_type', type: 'STRING', value: '', format: '', inputType: 'Select',
                        selectOptions: [{ value: '', text: 'Todos' }, { value: 'COLLECTIVE', text: 'Colectivo' }, { value: 'EXCLUSIVE', text: 'Exclusivo' }]
                    },
                    { column: 'passengers', type: 'STRING', value: '', format: '' },
                    { column: 'so.operator_name', type: 'STRING', value: '', format: '' },
                    { column: 'vehicle', type: 'STRING', value: '', format: '' },
                    { column: 'so.status', type: 'BOOLEAN', value: '', inputType: 'Select' }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'so.start_date',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Ordenes de Servicio',
                path: []
            }
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.serviceOrders, this.tableState);
    },
    methods: {
        formatCurrency: formatCurrency,
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.serviceOrders.filters.filter(filter => filter.value.trim() !== ''),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(serviceOrderListUrl(), data).then(response => {
                    if (response.data.code == constants.CODE_OK) {
                        this.serviceOrders.totalRows = response.data.totalRows;
                        return response.data.serviceOrders;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        tableContextChanged(context) {
            this.serviceOrders.sortBy = context.sortBy;
            this.serviceOrders.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.serviceOrders);
        }
    },
    watch: {
        'serviceOrders.filters': {
            handler() {
                this.$refs.serviceOrdersTable.refresh();
                this.saveTableState(this.$route.name, this.serviceOrders);
            },
            deep: true
        }
    },
    computed: {
        start: function() {
            return this.serviceOrders.totalRows === 0 ? 0 : (this.serviceOrders.currentPage -1) * this.serviceOrders.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.serviceOrders.currentPage - 1) * this.serviceOrders.itemsPerPage;

            return (this.serviceOrders.totalRows - offset) < this.serviceOrders.itemsPerPage ? this.serviceOrders.totalRows: offset + this.serviceOrders.itemsPerPage;
        }
    }
};
</script>

<style scoped>

</style>